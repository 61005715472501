


import React, { useEffect, useState,Suspense } from "react";
import Helmet from "react-helmet";
const LandingContent = React.lazy(() => import("../components/LandingContent"));

const LandingPage = ({ scrollTo }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const handleLoad = () => {
      setIsLoaded(true);
    };

    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 400);

    if (document.readyState === "complete") {
      handleLoad();
      clearTimeout(timer);
    } else {
      window.addEventListener("load", handleLoad);
    }

    return () => {
      window.removeEventListener("load", handleLoad);
      clearTimeout(timer);
    };
  }, []);

  return (
    <>
      <Helmet>
        <link rel="canonical" href="https://checklynx.com/" />
        <title>
          Sanctions Screening & PEP Compliance Software – Checklynx | $99/month AML/CTF Solution
        </title>
        <meta
          name="description"
          content="Checklynx offers automated sanctions screening and PEP for AML/CTF compliance for just $99/month. Enhance AML compliance, reduce false positives, and streamline onboarding."
        />
      </Helmet>

      {!isLoaded ? (
        <LandingTitle />
      ) : (
        <Suspense >
          <LandingContent scrollTo={scrollTo} />
        </Suspense>
      )}
    </>
  );
};

export default LandingPage;

const LandingTitle = () => (
  <div style={mainSectionStyle}>
    <div style={sectionHeroLayoutStyle}>
      
      
    </div>
  </div>
);


const mainSectionStyle = {
  display: "flex",
  paddingTop: "7%",
  paddingLeft: "5%",
  paddingRight: "3%",
  flexDirection: "column",
  marginBottom: "5%",
};
const sectionHeroLayoutStyle = {
  alignItems: "start",
  flexDirection: "column",
  paddingRight: "3%",
  maxWidth: "480px",
  zIndex: 0,
  
};

const h1Style = {
  fontSize: "3.8rem",
  lineHeight: "4rem",
  fontWeight: "bold",
  
  color:"black",
  marginBottom: "10%",
  
};
const h2Style = {
  fontSize: "2.9rem",
  lineHeight: "3rem",
  marginBottom: "10%",
  color:"black",
};

