import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';

import LandingPage from './pages/LandingPage';
// Lazy load the other pages



const CustomerScreeningPage = React.lazy(() => import('./pages/CustomerScreeningPage'));
const CustomerOngoingMonitoring = React.lazy(() => import('./pages/CustomerOngoingMonitoring'));
const PrivacyNotice = React.lazy(() => import('./pages/PrivacyNotice'));
const Terms = React.lazy(() => import('./pages/Terms'));
const ContactUs = React.lazy(() => import('./pages/ContactUs'));
const PEPscreeningPage = React.lazy(() => import('./pages/PEPScreeningPage'));
const CryptoAssetCompliancePage = React.lazy(() => import('./pages/CryptoAssetCompliancePage'));
const MaritimeAviationScreeningPage = React.lazy(() => import('./pages/MaritimeAviationCompliancePage'));
const CaseManagementPage = React.lazy(() => import('./pages/CaseManagementPage'));
const AuditReportingPage = React.lazy(() => import('./pages/AuditReportingPage'));




// App Component
function App() {
  
  return (
   
      
      <Router>
      <Suspense >
        <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="*" element={<LandingPage />} />
        <Route path="/pricing" element={<LandingPage scrollTo="pricing" />} />
        <Route path="/solutions/aml-sanctions-screening" element={<CustomerScreeningPage />} />
        <Route path="/solutions/aml-customer-ongoing-monitoring" element={<CustomerOngoingMonitoring />} />
        <Route path="/solutions/aml-pep-screening" element={<PEPscreeningPage />} />
        <Route path="/solutions/aml-crypto-assets-screening" element={<CryptoAssetCompliancePage />} />
        <Route path="/solutions/aml-maritime-aviation-screening" element={<MaritimeAviationScreeningPage />} />
        <Route path="/solutions/aml-case-management" element={<CaseManagementPage />} />
        <Route path="/solutions/aml-customer-risk-management" element={<PEPscreeningPage />} />
        <Route path="/solutions/aml-audit-reporting" element={<AuditReportingPage />} />
        <Route path="/privacy" element={<PrivacyNotice />} />
        <Route path="/terms" element={<Terms />} />
        
        <Route path="/contact" element={<ContactUs />} />
        </Routes>
        </Suspense>
      </Router>
     
    
      
      
  );
}

export default App;